import i18n from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import XHR from "i18next-xhr-backend"

import translationEn from "./locales/en/translation.json"
import translationEs from "./locales/es/translation.json"
import translationPt from "./locales/pt/translation.json"
import translationBr from "./locales/br/translation.json"

let lng = new URLSearchParams(window.location.search).get('lng')
if(!lng){
  try{
    lng = navigator.language.toLocaleLowerCase()
    if(lng.includes('en') || lng.includes('us'))
      lng = 'en'
    if(lng.includes('es'))
      lng = 'es'
    if(lng.includes('pt') || lng.includes('br'))
      lng = 'br'
  } catch (e) {
    console.log('browser default language not found')
  }
}
  
i18n
  .use(XHR)
  .use(LanguageDetector)
  .init({
    debug: false,
    lng: lng?lng:"br",
    fallbackLng: "br",

    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },

    resources: {
      br: {
        translations: translationBr,
      },
      en: {
        translations: translationEn,
      },
      es: {
        translations: translationEs,
      },
      pt: {
        translations: translationPt,
      },      
    },
    ns: ["translations"],
    defaultNS: "translations",
  });

export default i18n